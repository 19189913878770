import {
  getAllCountries,
  getAllProvinces,
  getDistrictByCode,
  getDistrictsByProvince,
  getProvinceByCode,
  getWardByCode,
  getWardsByDistrict,
} from 'clients/AddressClient';
import { getFirst, isValid } from 'clients/Clients';

export async function getProvinces(ctx) {
  const provincesRes = await getAllProvinces({ ctx });
  if (!isValid(provincesRes)) {
    return [];
  }
  const provinces = provincesRes.data
    .map(({ name: label, code: value, regionCode }) => ({ label, value, regionCode }))
    .sort((a, b) => a.label.localeCompare(b.label));
  return provinces;
}

export async function getCountriesOption({ ctx } = {}) {
  const countryRes = await getAllCountries({ ctx });
  if (!isValid(countryRes)) {
    return [];
  }
  const countries = countryRes.data.map(({ name: label, code: value }) => ({ label, value })).sort((a, b) => a.label.localeCompare(b.label));
  return countries;
}

export async function getDistrictsOptionsByProvinceNoAuth(provinceCode, isAuth, isBasic) {
  const res = await getDistrictsByProvince(provinceCode, isAuth, isBasic);
  if (!isValid(res)) {
    return [];
  }
  return res.data.map(({ name: label, code: value }) => ({ label, value })).sort((a, b) => a.label.localeCompare(b.label));
}

export async function getWardsOptionByDistrictNoAuth(districtCode, isAuth, isBasic) {
  const res = await getWardsByDistrict(districtCode, isAuth, isBasic);
  if (!isValid(res)) {
    return [];
  }
  return res.data.map(({ name: label, code: value }) => ({ label, value })).sort((a, b) => a.label.localeCompare(b.label));
}

export async function getDistrictOptionsByProvince(provinceCode) {
  const res = await getDistrictsByProvince(provinceCode);
  if (!isValid(res)) {
    return [];
  }
  return res.data.map(({ name: label, code: value }) => ({ label, value })).sort((a, b) => a.label.localeCompare(b.label));
}

export async function getWardOptionsByDistrict(districtCode) {
  const res = await getWardsByDistrict(districtCode);
  if (!isValid(res)) {
    return [];
  }
  return res.data.map(({ name: label, code: value }) => ({ label, value })).sort((a, b) => a.label.localeCompare(b.label));
}

export async function getMasterAddressString({ ctx, provinceCode, districtCode, wardCode }) {
  const [wardRes, districtRes, provinceRes] = await Promise.all([
    wardCode && getWardByCode({ ctx, wardCode }),
    getDistrictByCode({ ctx, code: districtCode }),
    getProvinceByCode({ ctx, code: provinceCode }),
  ]);
  let masterAddress = '';
  if (isValid(wardRes)) masterAddress += `${getFirst(wardRes).name}, `;
  if (isValid(districtRes)) masterAddress += `${getFirst(districtRes).name}, `;
  if (isValid(provinceRes)) masterAddress += getFirst(provinceRes).name;
  return masterAddress;
}

export async function getMasterAddressObject({ ctx, provinceCode, districtCode, wardCode }) {
  const [wardRes, districtRes, provinceRes] = await Promise.all([
    wardCode && getWardByCode({ ctx, wardCode }),
    getDistrictByCode({ ctx, code: districtCode }),
    getProvinceByCode({ ctx, code: provinceCode }),
  ]);
  const masterAddressObj = {};
  if (isValid(wardRes)) masterAddressObj.ward = getFirst(wardRes)?.name || '';
  if (isValid(districtRes)) masterAddressObj.district = getFirst(districtRes)?.name || '';
  if (isValid(provinceRes)) masterAddressObj.province = getFirst(provinceRes)?.name || '';

  return masterAddressObj;
}

export default {
  getProvinces,
  getMasterAddressString,
  getMasterAddressObject,
};
