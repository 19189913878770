import Overlay from 'components-v2/atoms/Overlay';
import { IStepCategory } from 'constants/data/guideline/types';
import events from 'events';
import Image, { StaticImageData } from 'next/image';
import { useEffect, useRef, useState } from 'react';
import { getLinkProxyCDN } from 'utils/ImageUtils';
import { debounce, throttle } from 'utils/timer';
import Hole from './Hole';
import styles from './styles.module.css';

export const containerEvents = new events.EventEmitter();

type Props = {
  // scroll?: boolean;
  //
};

type IDirection = 'top' | 'left' | 'right' | 'bottom';

function TourGuidePopover(props: Props) {
  const [open, setOpen] = useState(false);
  const [data, setData] = useState<{
    highlightId: string;
    content: string;
    currentId: string;
    currentStep: number;
    totalSteps: number;
    x: number;
    y: number;
    subAction: undefined | string;
    direction: IDirection;
    index: number;
    onNext: (currentId: string, subAction?: string) => void;
    onBack: (currentId: string, subAction?: string) => void;
    image: undefined | StaticImageData;
    onClose: () => void;
    canBack: boolean;
    isMobile: boolean;
    category?: IStepCategory;
    fixedTop?: number;
    isArrow?: boolean;
    canKnown?: boolean;
    hole: { left: number; width: number; top: number; height: number } | undefined;
    style?: React.CSSProperties;
    offsetVertical?: number;
    delay?: number;
  }>({
    highlightId: '',
    content: '',
    currentId: '',
    currentStep: 0,
    totalSteps: 0,
    fixedTop: 0,
    x: 0,
    y: 0,
    subAction: undefined as undefined | string,
    direction: 'bottom' as IDirection,
    index: 0,
    onNext: (currentId: string, subAction?: string) => {
      //
    },
    onBack: (currentId: string, subAction?: string) => {
      //
    },
    image: undefined as undefined | StaticImageData,
    onClose: () => {
      //
    },
    canBack: false,
    isMobile: false,
    isArrow: true,
    canKnown: false,
    hole: undefined,
  });

  const preId = useRef<string | undefined>(undefined);
  const holeRef = useRef<HTMLDivElement>(null);
  const overlayRef = useRef<HTMLDivElement>(null);
  const calTop = (id: string) => {
    const element = document.getElementById(id);
    if (!element) return;
    const rect = element.getBoundingClientRect();
    const topXCoord = window.scrollX + rect.left + rect.width / 2; // Toạ độ x của giữa top
    const topYCoord = window.scrollY + rect.top; // Toạ độ y của top
    const fixedTop = rect.top;
    return {
      x: topXCoord,
      y: topYCoord,
      max: topYCoord,
      fixedTop,
      hold: {
        left: (window.scrollX || 0) + rect.left,
        width: rect.width,
        top: (window.scrollY || 0) + rect.top,
        height: rect.height,
      },
    };
  };
  const calBottom = (id: string) => {
    const element = document.getElementById(id);
    if (!element) return;
    const rect = element.getBoundingClientRect();
    const bottomXCoord = window.scrollX + rect.left + rect.width / 2; // Toạ độ x của giữa bottom
    const bottomYCoord = window.scrollY + rect.top + rect.height; // Toạ độ y của bottom
    return {
      x: bottomXCoord,
      y: bottomYCoord,
      hold: {
        left: window.scrollX + rect.left,
        width: rect.width,
        top: window.scrollY + rect.top,
        height: rect.height,
      },
    };
  };
  const calLeft = (id: string) => {
    const element = document.getElementById(id);
    if (!element) return;
    const rect = element.getBoundingClientRect();
    const leftXCoord = window.scrollX + rect.left; // Toạ độ x lề trái
    const leftYCoord = window.scrollY + rect.top + rect.height / 2; // Toạ độ y giữa lề trái
    return {
      x: leftXCoord,
      y: leftYCoord,
      hold: {
        left: window.scrollX + rect.left,
        width: rect.width,
        top: window.scrollY + rect.top,
        height: rect.height,
      },
    };
  };

  const calRight = (id: string) => {
    const element = document.getElementById(id);
    if (!element) return;
    const rect = element.getBoundingClientRect();
    const rightXCoord = window.scrollX + rect.left + rect.width; // Toạ độ x lề phải
    const rightYCoord = window.scrollY + rect.top + rect.height / 2; // Toạ độ y giữa lề phải
    return {
      x: rightXCoord,
      y: rightYCoord,
      max: window.scrollX + window.innerWidth - (rect.left + rect.width),
      hold: {
        left: window.scrollX + rect.left,
        width: rect.width,
        top: window.scrollY + rect.top,
        height: rect.height,
      },
    };
  };

  const handleClose = () => {
    setOpen(false);
    document.body.style.overflow = 'auto'; // Mở lại scroll
    // const stickyTopElement = document.getElementById('sticky_top');
    // if (stickyTopElement) stickyTopElement.style.position = 'sticky';
    // data.onClose?.();
  };

  const handleNext = (currentId: string) => {
    data.onNext(currentId, data.subAction);
  };

  const handleKnowIt = () => {
    setOpen(false);
    document.body.style.overflow = 'auto'; // Mở lại scroll
    // const stickyTopElement = document.getElementById('sticky_top');
    // if (stickyTopElement) stickyTopElement.style.position = 'sticky';
    data.onClose?.();
  };

  // useEffect(() => {
  //   return () => {
  //     if (open) {
  //       handleClose();
  //     }
  //   };
  // }, [open]);
  useEffect(() => {
    return () => {
      handleClose();
    };
  }, []);

  useEffect(() => {
    containerEvents.on(
      'push',
      (
        id: string,
        content: string,
        currentId: string,
        currentStep: number,
        totalSteps: number,
        onNext: (currentId: string) => void,
        onBack: (currentId: string) => void,
        subAction?: string,
        image?: StaticImageData,
        onClose?: () => void,
        index?: number,
        canBack?: boolean,
        isMobile?: boolean,
        category?: IStepCategory,
        isArrow?: boolean,
        canKnown?: boolean,
        style?: React.CSSProperties,
        offsetVertical?: number,
        delay?: number,
        ignoreRecaculate?: boolean,
        placement?: 'top' | 'left' | 'right' | 'bottom',
        isScroll?: boolean,
      ) => {
        debounce(() => {
          // Lấy tọa độ của từng phần tử
          // Lấy tọa độ của từng phần tử
          const preElement = preId.current && document.getElementById(preId.current);
          // const preX = preElement ? window.scrollX + preElement.getBoundingClientRect().left : 0;
          const preY = preElement ? window.scrollY + preElement.getBoundingClientRect().top : 0;

          const element = document.getElementById(id);
          if (!element) return;
          const offset: {
            x: number;
            y: number;
            max: number;
            direction: IDirection;
            fixedTop?: number;
            hole?: {
              top: number;
              left: number;
              width: number;
              height: number;
            };
          } = { x: 0, y: 0, max: 0, direction: 'bottom' as IDirection };

          const caculatedOffset = () => {
            switch (placement) {
              case 'top': {
                const caled = calTop(id);
                offset.x = caled?.x || 0;
                offset.y = caled?.y || 0;
                offset.max = caled!.max!;
                offset.direction = 'top';
                offset.fixedTop = caled?.fixedTop;
                offset.hole = caled?.hold;
                break;
              }
              case 'bottom': {
                const caled = calBottom(id);
                offset.x = caled?.x || 0;
                offset.y = caled?.y || 0;
                offset.max = window.scrollX + window.innerHeight - caled!.y;
                offset.hole = caled?.hold;
                break;
              }
              case 'left': {
                const caled = calLeft(id);
                offset.x = caled?.x || 0;
                offset.y = caled?.y || 0;
                offset.max = caled?.x || 0;
                offset.direction = 'left';
                offset.hole = caled?.hold;
                break;
              }
              case 'right': {
                const caled = calRight(id);
                offset.x = caled?.x || 0;
                offset.y = caled?.y || 0;
                offset.max = caled!.max!;
                offset.direction = 'right';
                offset.hole = caled?.hold;
                break;
              }
              default: {
                {
                  const caled = calBottom(id);
                  offset.x = caled?.x || 0;
                  offset.y = caled?.y || 0;
                  offset.max = window.scrollX + window.innerHeight - caled!.y;
                  offset.hole = caled?.hold;
                }
                {
                  const caled = calRight(id);
                  if (window.scrollX + window.innerWidth - (caled!.x > 0 ? caled!.x! : 0) > offset.max) {
                    offset.x = caled?.x || 0;
                    offset.y = caled?.y || 0;
                    offset.max = caled!.max!;
                    offset.direction = 'right';
                    offset.hole = caled?.hold;
                  }
                }
                {
                  const caled = calTop(id);
                  if ((caled!.max > 0 ? caled!.max! : 0) > offset.max) {
                    offset.x = caled?.x || 0;
                    offset.y = caled?.y || 0;
                    offset.max = caled!.max!;
                    offset.direction = 'top';
                    offset.fixedTop = caled?.fixedTop;
                    offset.hole = caled?.hold;
                  }
                }
                {
                  const caled = calLeft(id);
                  if ((caled?.x || 0) > offset.max) {
                    offset.x = caled?.x || 0;
                    offset.y = caled?.y || 0;
                    offset.max = caled?.x || 0;
                    offset.direction = 'left';
                    offset.hole = caled?.hold;
                  }
                }
                break;
              }
            }
          };
          caculatedOffset();
          const data = {
            highlightId: id,
            content,
            currentId,
            currentStep,
            totalSteps,
            onNext,
            onBack,
            subAction,
            image,
            onClose: onClose!,
            canBack: canBack || false,
            index: index || 0,
            isMobile: isMobile || false,
            category: category || 'spotlight',
            direction: offset.direction,
            x: offset.x || 0,
            y: offset.y || 0,
            fixedTop: offset.fixedTop,
            offsetVertical,
            hole: offset.hole,
            canKnown,
            style,
            isArrow: isArrow === undefined ? true : isArrow,
          };
          setData(data);
          preId.current = currentId;
          const scrollToElement = (currentElement: HTMLElement) => {
            const elementRect = currentElement.getBoundingClientRect();
            const offsetFromCenter = window.innerHeight / 2 - elementRect.height / 2 + (offsetVertical || 0);

            window.scrollTo({
              top: elementRect.top + window.scrollY - offsetFromCenter,
              behavior: 'smooth',
            });
          };
          {
            const currentElement = document.getElementById(currentId);
            if (!currentElement) return;
            // const currentX = currentElement ? window.scrollX + currentElement.getBoundingClientRect().left : 0;
            const currentY = currentElement ? window.scrollY + currentElement.getBoundingClientRect().top : 0;
            if (Math.abs(currentY - preY) > 200) {
              if (currentElement) {
                if (isScroll) scrollToElement(currentElement);

                if (!ignoreRecaculate) {
                  setTimeout(() => {
                    if (holeRef.current) {
                      caculatedOffset();
                      setData({
                        ...data,
                        direction: offset.direction,
                        x: offset.x || 0,
                        y: offset.y || 0,
                        fixedTop: offset.fixedTop,
                        offsetVertical,
                        hole: offset.hole,
                      });

                      // Kiểm tra thành công, xóa interval
                    }
                  }, 400);
                }
              }
            }
          }
        }, delay || 0)();
      },
    );

    containerEvents.on(
      'open',
      throttle(() => {
        document.body.style.overflow = 'hidden';
        // const stickyTopElement = document.getElementById('sticky_top');
        // if (stickyTopElement) stickyTopElement.style.position = 'unset';

        setOpen(true);
      }, 300),
    );

    containerEvents.on('close', () => {
      handleClose();
    });
  }, []);
  if (!open) return null;

  return (
    <div className={styles.container}>
      {open && (
        <>
          <Overlay style={{ mixBlendMode: 'hard-light' }} overlayRef={overlayRef} className={styles.overlay}>
            {data.category && data.hole && (
              <Hole style={data.style} overlayRef={overlayRef} holeRef={holeRef} category={data.category} hole={data.hole} />
            )}
          </Overlay>
          {data.category && (
            <Tooltip
              top={data.y}
              left={data.x}
              text={data.content}
              currentId={data.currentId}
              direction={data.direction}
              current={data.currentStep}
              total={data.totalSteps}
              onNext={handleNext}
              onBack={(currentId: string) => {
                data.onBack(currentId, data.subAction);
              }}
              index={data.index}
              canBack={data.canBack}
              isMobile={data.isMobile}
              category={data.category}
              image={data.image}
              handleNext={handleNext}
              handleKnown={handleKnowIt}
              fixedTop={data.fixedTop}
              isArrow={data.isArrow}
              canKnown={data.canKnown}
              height={data.hole?.height}
              width={data.hole?.width}
            />
          )}
        </>
      )}
    </div>
  );
}

const Tooltip = ({
  text,
  direction,
  top,
  left,
  currentId,
  current,
  total,
  onNext,
  onBack,
  index,
  canBack,
  isMobile,
  category,
  image,
  handleNext,
  handleKnown,
  fixedTop,
  isArrow,
  canKnown,
  width,
  height,
}: {
  text: string;
  direction: 'top' | 'left' | 'right' | 'bottom';
  top: number;
  left: number;
  currentId: string;
  current: number;
  total: number;
  onNext: (currentId: string) => void;
  onBack: (currentId: string) => void;
  index: number;
  canBack: boolean;
  isMobile: boolean;
  category: IStepCategory;
  image?: StaticImageData;
  handleNext?: (currentId: string) => void;
  handleKnown?: () => void;
  fixedTop?: number;
  isArrow?: boolean;
  canKnown?: boolean;
  width?: number;
  height?: number;
}) => {
  const defaultStyle = isMobile
    ? ({
        zIndex: 9999,
        // position: 'fixed',
        left: '50%',
        transform: 'translate(-50%, 0)',
        width: '100vw',
        top,
      } as React.CSSProperties)
    : ({
        top: top || 80,
        left: left || 80,
        display: 'flex',
      } as React.CSSProperties);

  return (
    <>
      {!isMobile && (
        <>
          {category === 'spotlight' && (
            <div
              id="tooltip"
              className={styles.tooltip}
              style={
                (direction === 'bottom' && {
                  ...defaultStyle,
                  top: top + 20,
                  transform: `translate(-50%, ${0}px)`,
                }) ||
                (direction === 'top' && {
                  ...defaultStyle,
                  top: top - 20,
                  transform: `translate(-50%, -100%)`,
                }) ||
                (direction === 'right' && {
                  ...defaultStyle,
                  left: left + 20,
                  top,
                  transform: `translate(0, -50%)`,
                  alignItems: 'center',
                }) ||
                (direction === 'left' && {
                  ...defaultStyle,
                  left: left - 20,
                  top,
                  transform: `translate(-100%,-50%)`,
                  alignItems: 'center',
                }) ||
                defaultStyle
              }
            >
              <div className={styles.container}>
                {isArrow && (
                  <>
                    {direction === 'bottom' && <div className={styles['arrow-up']} />}
                    {direction === 'top' && <div className={styles['arrow-down']} />}
                    {direction === 'right' && <div className={styles['arrow-left']} />}
                    {direction === 'left' && <div className={styles['arrow-right']} />}
                  </>
                )}
                <div className={styles.wrapper}>
                  <div className={styles.contentBox}>
                    <div className={styles.content}>{text}</div>
                    <div className={styles.bottomTip}>
                      <h6 className={styles.step}>{index ? `${index} / ${total}` : ''}</h6>
                      <div className={styles.boxButton}>
                        {canBack && (
                          <button onClick={() => onBack(currentId)} className={styles.buttonBack}>
                            Quay lại
                          </button>
                        )}
                        <button onClick={() => onNext(currentId)} className={styles.buttonNext}>
                          {index !== total && 'Tiếp theo'}
                          {index === total && 'Tôi đã hiểu'}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {category === 'sheet' && (
            <>
              <div className={styles.imageContainer}>
                <div className={styles.imageTitle}>
                  <Image alt="spin_promotion" src={image!} width={350} height={300} className={styles.image} />
                </div>
                <div className={styles.imageContent}>
                  <p>{text}</p>
                </div>
                <div className={styles.buttonBox}>
                  <button
                    className={styles.buttonKnown}
                    onClick={() => {
                      handleKnown?.();
                    }}
                  >
                    Tôi đã hiểu
                  </button>
                  <button
                    onClick={() => {
                      onNext(currentId);
                    }}
                    className={styles.buttonStart}
                  >
                    Bắt đầu
                  </button>
                </div>
              </div>
            </>
          )}
        </>
      )}
      {isMobile && (
        <>
          {category === 'sheet' && (
            <div className={styles.sheetContainer}>
              <div className={styles.sheetBox}>
                {image && (
                  <div className={styles.imageTitle}>
                    <img src={getLinkProxyCDN(image.src)} alt="spin_alt" width={200} height={150} className={styles.image} />
                  </div>
                )}

                {/* {step.title && <div>{step.title}</div>} */}
                <p className={styles.content}>{text}</p>
                <div className={styles.bottomIndicator}>
                  <div className={styles.buttonBox}>
                    {canKnown && (
                      <button className={styles.buttonKnown} onClick={handleKnown}>
                        Tôi đã hiểu
                      </button>
                    )}
                    {handleNext && (
                      <button className={styles.buttonNext} onClick={() => handleNext(currentId)}>
                        Bắt đầu
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
          {category === 'spotlight' && (
            <div
              id="tooltip"
              className={styles.tooltip}
              style={
                (direction === 'bottom' && {
                  ...defaultStyle,
                  top: top + 20,
                  transform: `translate(-50%, ${0}px)`,
                }) ||
                (direction === 'top' && {
                  ...defaultStyle,
                  top: top - 20,
                  transform: 'translate(-50%, -100%)',
                }) ||
                (direction === 'right' && {
                  ...defaultStyle,
                  left: left + 20,
                  transform: `translate(100%, -50%)`,
                  alignItems: 'center',
                }) ||
                (direction === 'left' && {
                  ...defaultStyle,
                  left: left - 20,
                  top,
                  transform: `translate(-100%,-50%)`,
                  alignItems: 'center',
                }) ||
                defaultStyle
              }
            >
              <div className={styles.container}>
                {isArrow && (
                  <>
                    {direction === 'bottom' && <div className={styles['arrow-up']} />}
                    {direction === 'top' && <div className={styles['arrow-down']} />}
                    {direction === 'right' && <div className={styles['arrow-left']} />}
                    {direction === 'left' && <div className={styles['arrow-right']} />}
                  </>
                )}
                <div className={styles.wrapper}>
                  <div className={styles.contentBody}>
                    <div className={styles.content}>{text}</div>
                    <div className={styles.bottomTip}>
                      <h6 className={styles.step}>{index ? `${index} / ${total}` : ''}</h6>
                      <div className={styles.boxButton}>
                        {canBack && (
                          <button onClick={() => onBack(currentId)} className={styles.buttonBack}>
                            Quay lại
                          </button>
                        )}
                        <button onClick={() => onNext(currentId)} className={styles.buttonNext}>
                          {index !== total && 'Tiếp theo'}
                          {index === total && 'Tôi đã hiểu'}
                        </button>
                      </div>
                    </div>
                  </div>
                  {/* {index && (
                  <Progressbar
                    index={index}
                    total={total}
                    handleNext={()=>{
                    handleNext?.(currentId)
                  }}
                  />
                  )} */}
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default TourGuidePopover;
