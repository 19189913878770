import Collapse from '@material-ui/core/Collapse';
import Container from '@material-ui/core/Container';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Alert from '@material-ui/lab/Alert';
import { ACCOUNT, HOME } from 'constants/Paths';
import Cookies from 'js-cookie';
import { useState } from 'react';
import { GENERAL_DOMAIN, NOTIFY_IN_TOP_BAR, NOTIFY_IN_TOP_BAR_UIV2 } from 'sysconfig';
import { checkTrialSelected } from 'utils/transVersion';
import { useStore } from 'zustand-lib/storeGlobal';
import styles from './styles.module.css';

const AlertSection = ({
  beta,
  pathname,
  isMobile,
  notifyWidth,
  marginTopForHeader,
  isAuthenticated,
  status,
  isMobileProdPage = false,
  isMobileV2 = false,
  isInvalidLicense = false,
}) => {
  const KEY_COOKIES = 'hasHidden';
  const offNotify = beta && pathname === ACCOUNT;
  const user = useStore((state) => state?.user || {});
  const hasHidden = Cookies.get(KEY_COOKIES);
  const [open, setOpen] = useState(hasHidden !== 'true');
  const showTrial = pathname === HOME || pathname === '/conversations';
  const hideNotifyInTop = [ACCOUNT, '/conversations'];

  return (
    <div>
      {!hideNotifyInTop.includes(pathname) && (
        <div style={{ minHeight: isMobile ? 'none' : `${notifyWidth}px`, marginTop: marginTopForHeader() }}>
          {NOTIFY_IN_TOP_BAR && (
            <Container maxWidth="lg" style={{ maxWidth: '1352px', padding: isMobileProdPage && '7px 16px' }}>
              <Collapse in={open}>
                <Alert
                  severity="error"
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        Cookies.set(KEY_COOKIES, false, { domain: GENERAL_DOMAIN, expires: 90, sameSite: 'Lax' });
                        setOpen(false);
                      }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                  style={{ marginTop: '10px', marginBottom: '5px', borderRadius: '8px' }}
                >
                  {NOTIFY_IN_TOP_BAR}
                </Alert>
              </Collapse>
            </Container>
          )}

          {NOTIFY_IN_TOP_BAR_UIV2 && checkTrialSelected(user) && isMobileV2 && showTrial && (
            <Container maxWidth="lg" style={{ maxWidth: '1352px', padding: isMobileProdPage && '7px 16px' }}>
              <Collapse in={open}>
                <Alert
                  severity="info"
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        Cookies.set(KEY_COOKIES, true, { domain: GENERAL_DOMAIN, expires: 90, sameSite: 'Lax' });
                        setOpen(false);
                      }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                  style={{ marginBottom: '8px', borderRadius: '8px' }}
                >
                  {NOTIFY_IN_TOP_BAR_UIV2}
                </Alert>
              </Collapse>
            </Container>
          )}
        </div>
      )}

      {isAuthenticated && !offNotify && isInvalidLicense && (
        <Container maxWidth="lg" style={{ maxWidth: '1180px' }} disableGutters>
          <Alert
            severity="error"
            style={{ marginTop: '16px', marginBottom: '16px', borderRadius: '8px', display: 'flex', justifyContent: 'center' }}
            className={styles.containerToast}
          >
            <Typography className={styles.textError}>
              Tài khoản của quý khách chưa đầy đủ giấy phép, vui lòng cập nhật bổ sung giấy tờ. Để cập nhật quý khách vui lòng{' '}
              <b>
                <a href="/my-account?tab=3">bấm vào đây</a>
              </b>
              .
            </Typography>
          </Alert>
        </Container>
      )}

      {isAuthenticated && !offNotify && !isInvalidLicense && status !== 'ACTIVE' && (
        <Container maxWidth="lg" style={{ maxWidth: '1352px' }}>
          <Alert severity="error" style={{ marginTop: '10px', marginBottom: '10px', borderRadius: '8px', display: 'flex', justifyContent: 'center' }}>
            <Typography align="center">
              Lưu ý: Tài khoản của quý khách sẽ không được kích hoạt nếu quý khách không cung cấp đủ các thông tin được yêu cầu. Để hoàn tất quá trình
              đăng ký và kích hoạt tài khoản, quý khách vui lòng{' '}
              <b style={{ color: '#0e1983' }}>
                <a href="/my-account?tab=3">bấm vào đây</a>
              </b>{' '}
              và cung cấp giấy phép của quý khách hoặc{' '}
              <b style={{ color: '#0e1983' }}>
                <a href="https://hotro.thuocsi.vn/huong-dan-kich-hoat-tai-khoan-thuocsi-vn/">xem hướng dẫn tại đây.</a>
              </b>{' '}
            </Typography>
          </Alert>
        </Container>
      )}
    </div>
  );
};

export default AlertSection;
