import { spinContainer } from 'components-v2/mocules/Spinner';
import TourGuidePopover from 'components-v2/mocules/TourGuidePopover/TourGuidePopover';
import { useGuide } from 'components-v2/mocules/TourGuidePopover/useGuide';
import { GUIDE_FEATURE, allGuideLine } from 'constants/data/guideline';
import { IGuideline } from 'constants/data/guideline/types';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { getMeGuide, updateMeGuide } from 'services/CustomerService';

export default function GuideContext({ isMobile, children }: { children: React.ReactNode; isMobile: boolean }) {
  const guide = useGuide();
  const router = useRouter();

  useEffect(() => {
    const guideLine = getGuideLine(router.asPath, isMobile);
    if (!guideLine) return;

    (async () => {
      const meGuide = await getMeGuide(guideLine.feature);
      if (!meGuide.isActive || meGuide.isGuided) return;

      let firstId = handleGuideline(guide, guideLine, isMobile);
      let intervalId: ReturnType<typeof setInterval>;

      if (firstId) {
        intervalId = setInterval(() => {
          firstId = handleGuideline(guide, guideLine, isMobile);
          if (!firstId) {
            clearInterval(intervalId);
          }
        }, 300);
      }
    })();
  }, [router.asPath, isMobile]);

  return (
    <>
      <TourGuidePopover />
      {children}
    </>
  );
}

function getGuideLine(path: string, isMobile: boolean) {
  switch (path) {
    case '/': {
      return allGuideLine.find((guide) => guide.page.includes('/') && guide.isMobile === isMobile);
    }
    default: {
      if (path.startsWith('/lucky-wheel?wheelCode=')) {
        return allGuideLine[0];
      } else {
        return allGuideLine.find((guide) => guide.page.filter((x) => x !== '/').some((page) => path.includes(page) && guide.isMobile === isMobile));
      }
    }
  }
}

function handleGuideline(guide: ReturnType<typeof useGuide>, guideline: IGuideline, isMobile: boolean) {
  const steps = guideline.data;

  function createGuideStep(index: number) {
    if (index === -1) {
      guide.close();
      return;
    }

    const step = steps[index];
    const stepElement = document.getElementById(step?.id);
    const lastElement = document.getElementById(steps[steps.length - 1 >= 0 ? steps.length - 1 : 0].id);
    if (!stepElement || !lastElement) {
      guide.close();
      return;
    }

    if (step.subAction && guideline.feature === GUIDE_FEATURE.LUCKY_WHEEL) {
      switch (step.subAction) {
        case 'misson': {
          spinContainer.emit('misson');
          break;
        }
        case 'history': {
          spinContainer.emit('history');
          break;
        }
        case 'guideline': {
          spinContainer.emit('guideline');
          break;
        }
        default: {
          break;
        }
      }
    }

    guide.push(
      step.id,
      step.content,
      step.id,
      index + 1,
      guideline.data.filter((x) => Boolean(x.index)).length || 0,
      (currentId, subAction) => {
        const currentIndex = steps.findIndex((x) => x.id === currentId && (subAction ? x.subAction === subAction : true));
        if (currentIndex === -1) return;
        if (currentIndex === steps.length - 1) {
          updateMeGuide({ feature: guideline.feature, isViewAll: true });
          guide.close();
          return;
        }
        const next = steps[currentIndex + 1];
        let nextIndex = currentIndex + 1;
        if (currentId === next.id) {
          const currentIndex = steps.findIndex((x) => x.subAction === subAction);
          if (currentIndex === -1) return;
          nextIndex = currentIndex + 1;
        }
        if (!next) return;
        createGuideStep(nextIndex);
      },
      (currentId, subAction) => {
        const currentIndex = steps.findIndex((x) => x.id === currentId && (subAction ? x.subAction === subAction : true));
        if (currentIndex === -1) return;
        const pre = steps[currentIndex - 1];
        let preIndex = currentIndex - 1;
        if (currentId === pre.id) {
          const currentIndex = steps.findIndex((x) => x.subAction === subAction);
          if (currentIndex === -1) return;
          preIndex = currentIndex - 1;
        }
        if (!pre) return;
        createGuideStep(preIndex);
      },
      step.subAction,
      step.image,
      () => {
        updateMeGuide({ feature: guideline.feature });
        // guide.close();
      },
      step.index,
      step.canBack,
      isMobile,
      step.category,
      step.isArrow,
      step.canKnown,
      step.style,
      step.offsetVertical,
      step.delay,
      guideline.ignoreRecaculate,
      step.placement,
      step?.isScroll,
    );
  }

  const initialStep = steps[0];
  const checkFirstElement = document.getElementById(initialStep.id);
  if (!checkFirstElement) {
    return initialStep.id;
  }

  createGuideStep(0);
  guide.open();
}
