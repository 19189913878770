import { initializeApp } from '@firebase/app';
import { getMessaging, getToken, isSupported } from '@firebase/messaging';
import {
  FIREBASE_API_KEY,
  FIREBASE_APP_ID,
  FIREBASE_AUTH_DOMAIN,
  FIREBASE_MEASUREMENT_ID,
  FIREBASE_MESSAGING_SENDER_ID,
  FIREBASE_PROJECT_ID,
  FIREBASE_STORAGE_BUCKET,
  FIREBASE_SW_PATH,
  FIREBASE_VAPID_KEY,
  MARCOM_API_DOMAIN,
} from 'sysconfig';

const MARCOM_SERVICE = {
  WEB_REGISTER_TOKEN: `${MARCOM_API_DOMAIN}/insider/communication/v1/user-token/web-register`,
};

const useFirebase = (user) => {
  const firebaseConfig = {
    apiKey: FIREBASE_API_KEY,
    authDomain: FIREBASE_AUTH_DOMAIN,
    projectId: FIREBASE_PROJECT_ID,
    storageBucket: FIREBASE_STORAGE_BUCKET,
    messagingSenderId: FIREBASE_MESSAGING_SENDER_ID,
    appId: FIREBASE_APP_ID,
    measurementId: FIREBASE_MEASUREMENT_ID,
  };

  const checkSupportNotification = async () => {
    const hasFirebaseMessagingSupport = await isSupported();
    if (hasFirebaseMessagingSupport) {
      const firebaseApp = initializeApp(firebaseConfig);
      const messaging = getMessaging(firebaseApp);
      await requestPermission(messaging);
    }
  };

  const requestPermission = async (messaging) => {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.ready.then((registration) => {
        registration.update();
      });
    }
    if ('Notification' in window && 'permissions' in navigator) {
      const permission = await navigator.permissions.query({ name: 'notifications' });
      if (permission.state === 'granted') {
        // Permission already granted
        await registerServiceWorker(messaging);
      } else if (permission.state === 'prompt') {
        // Permission not granted, show notification prompt
        Notification.requestPermission().then(async (result) => {
          if (result === 'granted') {
            // Permission granted, register service worker and get token
            await registerServiceWorker(messaging);
          } else {
            // Handle when permission is denied
          }
        });
      } else {
        // Permission denied
        // Handle when permission is denied
      }
    } else {
      // Browser does not support notifications
      // Handle when browser does not support notifications
    }
  };

  const registerServiceWorker = async (messaging) => {
    try {
      if ('serviceWorker' in navigator) {
        const registrations = await navigator.serviceWorker.getRegistrations();
        const firebaseMessagingRegistrations = registrations.filter((registration) => {
          return registration.active && registration.active.scriptURL === FIREBASE_SW_PATH;
        });

        if (firebaseMessagingRegistrations.length > 0) {
          // Service worker cũ đã được cài đặt, cập nhật bằng service worker mới
          const registration = firebaseMessagingRegistrations[0];
          registration.update();
          getTokenUser(messaging, registration);
        } else {
          // Không có service worker nào được cài đặt, đăng ký service worker mới
          const registration = await navigator.serviceWorker.register(FIREBASE_SW_PATH, {
            updateViaCache: 'none',
          });

          registration.onupdatefound = () => {
            const installingWorker = registration.installing;
            if (installingWorker) {
              installingWorker.onstatechange = () => {
                if (installingWorker.state === 'activated') {
                  getTokenUser(messaging, registration);
                }
              };
            }
          };
        }
      }
    } catch (err) {
      console.error('Service worker registration failed: ', err);
    }
  };

  const getTokenUser = async (messaging, regis = '') => {
    try {
      // check service worker is activated
      if ('serviceWorker' in navigator && navigator.serviceWorker.controller?.state === 'activated') {
        // check user and user.customerID is defined
        if (messaging && user && user.customerID) {
          // get current token
          const currentToken = await getToken(messaging, { serviceWorkerRegistration: regis, vapidKey: FIREBASE_VAPID_KEY });

          // get token from session storage (Web Register Token - WRT)
          const sessionWRT = sessionStorage.getItem('WRT');

          // if current token is different from session token
          if (currentToken && currentToken !== sessionWRT) {
            // send token to marcom service
            const response = await fetch(MARCOM_SERVICE.WEB_REGISTER_TOKEN, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                projectID: 10001,
                userUniqueID: user.customerID.toString(),
                firebaseToken: currentToken,
                name: user.name,
                email: user.email,
                phoneNumber: user.phone,
              }),
            });

            // check response status
            if (response.status === 'OK' || response.status === 200) {
              // save token to session storage
              sessionStorage.setItem('WRT', currentToken);
            } else {
              console.error('Failed to register token. Status:', response.status);
            }
          }
        } else {
          console.error('User or user.customerID is not defined.');
        }
      }
    } catch (error) {
      console.error('An error occurred while processing token:', error);
    }
  };
  return [checkSupportNotification];
};
export default useFirebase;
