import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import LinkedIn from '@material-ui/icons/LinkedIn';
import Telegram from '@material-ui/icons/Telegram';
import Twitter from '@material-ui/icons/Twitter';
import WifiCallingIcon from '@mui/icons-material/WifiCalling';
import { postShareLog } from 'clients/PromoClient';
import { useAuth } from 'context/Auth';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { ENV } from 'sysconfig';
import { ENUM_POPUP_SHARING_ITEM } from '../Spinner/interface';
import styles from './styles.module.css';

type SocialPlatformType = 'TELEGRAM' | 'TWITTER' | 'VIBER' | 'LINKEDIN' | 'SKYPE' | 'ZALO';

interface Props {
  socialPlatform: SocialPlatformType;
  title: string;
  redirectLink: string;
  isMobile: boolean;
}

// const domainTs = 'https://thuocsi.vn';
const currentTime = new Date().getTime();

const SocialSharing = ({ socialPlatform, title = '', redirectLink = '', isMobile = false }: Props) => {
  const router = useRouter();
  const { pathname } = router;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { user }: any = useAuth();
  const { accountID, customerID } = user || {};

  const getEnvDomain = () => {
    if (ENV === 'prd' || ENV === 'uat') {
      return 'https://thuocsi.vn';
    }
    return 'https://web.v2-stg.thuocsi.vn';
  };

  const domainTs = getEnvDomain() + '/lucky-wheel';

  const logShared = async (platform: ENUM_POPUP_SHARING_ITEM) => {
    await postShareLog({
      customerID,
      accountID,
      source: isMobile ? 'thuocsi-mobile' : 'thuocsi-web',
      page: getEnvDomain() + '/' + pathname,
      screen: pathname.replace('/', ''),
      shareUrl: redirectLink || domainTs,
      platform,
    });
  };

  switch (socialPlatform) {
    case 'TELEGRAM': {
      return (
        <Grid item xs={3} md={3} className={styles.itemSharing_wrapper}>
          <Box className={styles.itemSharing_block}>
            <Link href={`https://t.me/share/url?url=${encodeURIComponent(redirectLink || domainTs)}&text=${title}`}>
              <a
                target="_blank"
                href={`https://t.me/share/url?url=${encodeURIComponent(redirectLink || domainTs)}&text=${title}`}
                rel="noreferrer"
                onClick={() => logShared(ENUM_POPUP_SHARING_ITEM.TELEGRAM)}
              >
                <Telegram style={{ color: '#54a9eb', width: 40, height: 40 }} />
              </a>
            </Link>
          </Box>
          <Typography variant="body2">Telegram</Typography>
        </Grid>
      );
    }
    case 'TWITTER': {
      return (
        <Grid item xs={3} md={3} className={styles.itemSharing_wrapper}>
          <Box className={styles.itemSharing_block}>
            <Link href={`https://twitter.com/intent/tweet?text=${title}&url=${encodeURIComponent(redirectLink || domainTs)}`}>
              <a
                target="_blank"
                href={`https://twitter.com/intent/tweet?text=${title}&url=${encodeURIComponent(redirectLink || domainTs)}`}
                rel="noreferrer"
                onClick={() => logShared(ENUM_POPUP_SHARING_ITEM.TWITTER)}
              >
                <Twitter style={{ color: '#379cec', width: 40, height: 40 }} />
              </a>
            </Link>
          </Box>
          <Typography variant="body2">Twitter</Typography>
        </Grid>
      );
    }
    case 'VIBER': {
      return (
        <Grid item xs={3} md={3} className={styles.itemSharing_wrapper}>
          <Box className={styles.itemSharing_block}>
            <Link href={`viber://forward?text=${encodeURIComponent(title + ' ' + (redirectLink || domainTs))}`}>
              <a
                target="_blank"
                href={`viber://forward?text=${encodeURIComponent(title + ' ' + (redirectLink || domainTs))}`}
                rel="noreferrer"
                onClick={() => logShared(ENUM_POPUP_SHARING_ITEM.VIBER)}
              >
                <WifiCallingIcon style={{ color: '#793aaa', width: 40, height: 40 }} />
              </a>
            </Link>
          </Box>

          <Typography variant="body2">Viber</Typography>
        </Grid>
      );
    }
    case 'LINKEDIN': {
      return (
        <Grid item xs={3} md={3} className={styles.itemSharing_wrapper}>
          <Link href={`https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(redirectLink || domainTs)}`}>
            <a
              target="_blank"
              href={` https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(redirectLink || domainTs)}`}
              rel="noreferrer"
              onClick={() => logShared(ENUM_POPUP_SHARING_ITEM.LINKEDIN)}
            >
              <LinkedIn style={{ color: '#2563bc', width: 40, height: 40 }} />
            </a>
          </Link>
          <Typography variant="body2">Linkedin</Typography>
        </Grid>
      );
    }
    default: {
      return <></>;
    }
  }

  return <></>;
};

export default SocialSharing;
