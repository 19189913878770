import Box from '@material-ui/core/Box';
import Button from "@material-ui/core/Button";
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Modal from '@material-ui/core/Modal';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Typography from '@material-ui/core/Typography';
import Close from '@material-ui/icons/Close';
import clsx from 'clsx';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import FacebookShareButton from 'utils/FacebookShare';
import ZaloShareButton from 'utils/ZaloShare';
import SocialSharing from '../SocialSharing';
import { ENUM_POPUP_SHARING_ITEM } from '../Spinner/interface';
import styles from './styles.module.css';

interface IPopupSharing {
  open: boolean;
  handleClose: () => void;
  listItem?: ENUM_POPUP_SHARING_ITEM[]; // FACEBOOK, ZALO
  isCopyLink?: boolean;
  isMobile?: boolean;
  luckyWheelCode?: string;
  gamificationDetailID?: number;
  redirectLink?: string;
  setOpenConfirmSharing?: Dispatch<SetStateAction<boolean>>;
  reRenderMission?: () => void;
}

const timer = 3000;

const styleSharing = {
  position: 'absolute' as const,
  top: '55%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: '#fff',
  borderRadius: '8px',
  boxShadow: 24,
  p: '12px 16px',
  color: '#000',
  display: 'flex',
  flexDirection: 'column',
  gap: 12,
};

const styleMobileSharing = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  bgcolor: '#fff',
  borderRadius: '8px',
  boxShadow: 24,
  p: '12px 16px',
  color: '#000',
  display: 'flex',
  flexDirection: 'column',
  gap: 12,
};

const PopupSharing = ({
  open,
  handleClose,
  listItem,
  isCopyLink = false,
  isMobile = false,
  luckyWheelCode,
  gamificationDetailID,
  redirectLink = '',
  setOpenConfirmSharing,
  reRenderMission,
}: IPopupSharing) => {
  const [isCopied, setCopied] = useState(false);
  const handleSaveToClipboard = async () => {
    const copyText = document.getElementById('sharingForm_input') as HTMLInputElement;
    // Select the text field
    if (copyText) {
      copyText.select();
      copyText.setSelectionRange(0, 99999); // For mobile devices
    }
    // Copy the text inside the text field
    // TODO: Navigator clipboard api needs a secure context (https)
    if (navigator.clipboard && window.isSecureContext) {
      await navigator.clipboard.writeText(copyText.value);
    } else {
      // Use the 'out of viewport hidden text area' trick
      const textArea = document.createElement('textarea');
      textArea.value = copyText.value;

      // Move textarea out of the viewport so it's not visible
      textArea.style.position = 'absolute';
      textArea.style.left = '-999999px';

      document.body.prepend(textArea);
      textArea.select();

      try {
        document.execCommand('copy');
        // Alert the copied text
        setCopied(true);
      } catch (error) {
        console.error(error);
      } finally {
        textArea.remove();
      }
    }
  };

  useEffect(() => {
    if (isCopied) {
      setTimeout(() => {
        setCopied(false);
      }, timer);
    }
  }, [isCopied]);

  return (
    <Modal open={open}>
      <Box sx={isMobile ? styleMobileSharing : styleSharing}>
        <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h6" component="h2" style={{ fontFamily: 'ggsm' }}>
            Chia sẻ
          </Typography>
          <IconButton onClick={handleClose} style={{ padding: 0, width: '32px', height: '32px' }}>
            <Close
              style={{
                stroke: '#c0c0c0',
                strokeWidth: 1,
              }}
            />
          </IconButton>
        </Box>
        <Grid container className={clsx(styles.sharingContent_wrapper, isMobile && styles.mobileSharingContent_wrapper)} spacing={2}>
          {listItem && listItem?.indexOf(ENUM_POPUP_SHARING_ITEM.FACEBOOK) >= 0 && (
            <FacebookShareButton
              luckyWheelCode={luckyWheelCode}
              isSharePopup
              gamificationDetailID={gamificationDetailID}
              redirectLink={redirectLink}
              setOpenConfirmSharing={setOpenConfirmSharing}
              isMobile={isMobile}
              reRenderMission={reRenderMission}
            />
          )}
          <SocialSharing title="Chia sẻ bài viết trên Telegram" socialPlatform="TELEGRAM" redirectLink={redirectLink} isMobile={isMobile} />
          <SocialSharing title="Chia sẻ bài viết trên Twitter" socialPlatform="TWITTER" redirectLink={redirectLink} isMobile={isMobile} />
          <SocialSharing title="Chia sẻ bài viết trên Linkedin" socialPlatform="LINKEDIN" redirectLink={redirectLink} isMobile={isMobile} />
          {listItem && listItem?.indexOf(ENUM_POPUP_SHARING_ITEM.ZALO) >= 0 && (
            <ZaloShareButton
              isSharePopup
              redirectLink={redirectLink}
              luckyWheelCode={luckyWheelCode}
              gamificationDetailID={gamificationDetailID}
              isMobile={isMobile}
            />
          )}
          {/* {isMobile && <SocialSharing title="Chia sẻ bài viết trên Viber" socialPlatform="VIBER" redirectLink={redirectLink} isMobile={isMobile} />} */}

          {isCopyLink && (
            <Box style={{ width: '100%', position: 'relative', height: 52 }}>
              <FormControl variant="outlined" className={styles.sharingContent_formControl}>
                <OutlinedInput
                  id="sharingForm_input"
                  value={`${window.location.host}/lucky-wheel`}
                  className={styles.sharingContent_input}
                  endAdornment={
                    <Button
                      style={{
                        maxWidth: 'max-content',
                        color: '#fff',
                        whiteSpace: 'nowrap',
                        top: 0,
                        right: 0,
                        backgroundColor: '#0fba69',
                        borderRadius: 30,
                        textTransform: 'unset',
                        padding: '4px 16px',
                      }}
                      onClick={handleSaveToClipboard}
                    >
                      <span style={{ padding: '2px 8px' }}>{isCopied ? 'Đã sao chép' : 'Sao chép'}</span>
                    </Button>
                  }
                  classes={{
                    focused: styles.sharingContent_inputFocused,
                    root: styles.sharingContent_inputFocused,
                  }}
                  readOnly
                />
              </FormControl>
            </Box>
          )}
        </Grid>
      </Box>
    </Modal>
  );
};

export default PopupSharing;
